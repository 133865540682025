import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
//import components from '@/components/UI'
import router from './router';
import axios from 'axios'
import { useStore } from './store/useStore'
import { useAuthStore } from './store/useAuthStore'

const pinia = createPinia()
const app = createApp(App)

// components.forEach(component => {
//     app.component(component.name, component)
// })

axios.interceptors.response.use(
    (response) => { return response },
    (error) => {
        const store = useStore()
        const authStore = useAuthStore()

        if (error.response.status === 422) {
            store.setErrors(error.response.data.errors);
        } else if (error.response.status === 400) {
            store.summaryError = error.response.data.message
        } else if (error.response.status === 401) {
            authStore.user = null
            store.authToken = null
            localStorage.removeItem("authToken");
            router.push({ name: "login" });
        }
        return Promise.reject(error);
        
    }
);

axios.interceptors.request.use(function(config) {
    config.headers.common = {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
        Accept: "application/json"
    };

    return config;
});

app
    .use(pinia)
    .use(router)
    .mount('#app')
