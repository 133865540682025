import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from "axios";

export const useStore = defineStore('main', () => {
  const accessGranted = ref(false)
  const authToken = ref()
  const errors = ref([])
  const summaryError = ref('')
  const partner = ref()
  const partnerSlug = ref('')
  const partnerGP = ref('')
  const referralCode = ref('')
  const medicalDetails = ref()
  const psfs = ref()
  const stagingCode = '7926551117066378'
  const redirectUrl = ref()
  const newMessagesCount = ref(0)
  const newPasswordRequired = ref(false)
  const redirectAfterAuthorizeUrl = ref('')
  const fromRoute = ref()

  const isAuth = computed(() => authToken.value ? true : false)

  function setErrors(aErrors){
    errors.value = aErrors
  }

  function setMedicalDetails(questions){
    if(questions && questions.step1 && questions.step2){
      medicalDetails.value = questions
    }
  }

  function setPsfs(questions){
    if(questions && Array.isArray(questions)){
      psfs.value = questions
    }
  }

  async function setReferralCode(aReferralCode){
  //   referralCode.value = aReferralCode

  //   if(aReferralCode == ''){
  //     localStorage.removeItem('referralCode')
  //   } else {
  //     localStorage.setItem('referralCode', referralCode.value)
  //   }

  //   await refreshPartner()
  }

  // async function refreshPartner(){
  //   let parameters = {};

  //   if(referralCode){
  //       parameters.referral_code = referralCode.value
  //   }

  //   try {
  //       const response = await axios.get(
  //           process.env.VUE_APP_API_URL + "treatment/partner", {
  //           params: parameters
  //       });
  //       partner.value = response.data.data;

  //   } catch (e) {
  //       console.log(e);
  //   }
  // }

  async function initPartner(aPartner, aPartnerGp){
    if(aPartner != '' && aPartnerGp != ''){
      partnerSlug.value = aPartner
      partnerGP.value = aPartnerGp
    }else{
      partnerSlug.value = ''
      partnerGP.value = ''
    }
    // if(partner.value) return

    // if(localStorage.getItem("referralCode") && localStorage.getItem("referralCode") != referralCode) {
    //   referralCode.value = localStorage.getItem("referralCode")
    // }
    
    // await refreshPartner()
  }

  const cyrb53 = function(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
    h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1>>>0);
  };

  return { isAuth, authToken, errors, summaryError, partnerSlug, partnerGP, setErrors, setReferralCode, initPartner, cyrb53, stagingCode,
    medicalDetails, setMedicalDetails, psfs, setPsfs, redirectUrl, newMessagesCount, redirectAfterAuthorizeUrl, newPasswordRequired,
    fromRoute
  }
})